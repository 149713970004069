<template>
  <v-card class="border-end shadow-none rounded-0">
    <div class="pa-5 text-center">
      <v-row class="mb-5" justify="center">
        <div class="border-three">
          <div class="border-two">
            <div class="border-one">
              <v-avatar size="84">
                <img
                  :src="addResourceImage(currentUser.avatar)"
                  @error="imageFallback($event)"
                />
              </v-avatar> 
            </div>
          </div>
        </div>
      </v-row>
      <h4 class="fs-22 fw-500 mb-1 lh-1 primary-vi">{{ currentUser.name }}</h4>
      <h4 v-if="showIdSwitch" class="fs-12 fw-500 mb-0 lh-1 opacity-50 mt-1">
        ID: {{ currentUser.id }}
      </h4>
      <div
        class="swiper-container text-truncate d-flex align-center justify-center pl-6"
        :class="showEmail ? 'primary--text opacity-90' : 'opacity-60'"
      >
        {{
          showEmail
            ? currentUser.email
              ? currentUser.email
              : currentUser.phone
            : '********'
        }}
        <img
          v-show="showEmail"
          @click="showEmail = false"
          src="@/assets/img/user-eye.png"
          width="20px"
          height="20px"
          style="margin-left: 6px"
        />
        <img
          v-show="!showEmail"
          @click="showEmail = true"
          src="@/assets/img/user-eye-close.png"
          width="20px"
          height="20px"
          style="margin-left: 6px"
        />
      </div>
    </div>

    <v-list nav class="px-0 user-side-nav">
      <UserMenu />
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import UserMenu from './UserMenu'
export default {
  components: { UserMenu },
  data: () => ({
    showEmail: true
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('app', ['showIdSwitch'])
  }
}
</script>

<style scoped>
.border-one {
  width: 97px;
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 149, 149, 0.2);
}
.border-two {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 149, 149, 0.1);
}
.border-three {
  width: 123px;
  height: 123px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 149, 149, 0.05);
}

.fc-vi {
  color: #FF3B3B;
}
</style>
